<template>
  <div :class="classes">
    <v-textarea
      ref="textarea"
      v-model="textareaValue"
      :class="['k2-textarea__v-textarea', isWarn && 'warn']"
      clear-icon="close"
      density="compact"
      variant="outlined"
      row-height="22px"
      :auto-grow="autoGrowable"
      :clearable="clearable"
      :disabled="isDisabled"
      :error="hasError"
      :readonly="isReadonly"
      :filled="isDisabled"
      :hide-details="true"
      :rows="rows"
      :placeholder="placeholder"
      :prepend-inner-icon="prependIcon"
      data-test="v-textarea"
      @keydown="onKeydownPress"
    >
      <template #prepend-inner>
        <div
          v-if="hasPrependInnerSlot"
          class="k2-textarea__prepend-inner-content"
        >
          <!-- @slot Prepend Inner slot for adding an icon/text to the left -->
          <slot name="prepend-inner" />
        </div>
      </template>
    </v-textarea>
  </div>
</template>

<script lang="ts">
import { computed, ref, useSlots } from 'vue'
import { VTextarea } from 'vuetify/components'

import { InputState } from 'komodo2/types.ts'

export default {
  name: 'KomodoTextarea'
}
</script>

<script setup lang="ts">
interface Props {
  /**
   * The component state
   * @values default, error, disabled, warn
   */
  state?: InputState
  /**
   * The text shown when nothing has been entered.
   */
  placeholder?: string
  /**
   * Whether or not an icon shows to clear input
   */
  clearable?: boolean
  /**
   * The text to determine which icon to prepend
   */
  prependIcon?: string
  /**
   * Whether the textarea should automatically grow based on text size
   */
  autoGrowable?: boolean
  /**
   * Whether or not textarea is growable on focus
   */
  isFocusGrowable?: boolean
  /**
   * The initial height of the textarea in rows.
   */
  rows?: number
}

const props = withDefaults(defineProps<Props>(), {
  state: InputState.Default,
  placeholder: undefined,
  clearable: false,
  prependIcon: undefined,
  autoGrowable: true,
  isFocusGrowable: true,
  rows: 1
})

/**
 * The v-model bound prop
 */
const textareaValue = defineModel<string | null>({ default: null })

const emit = defineEmits<{
  /**
   * Triggers when cmd + enter are pressed
   * @param payload
   */
  (e: 'keydown-enter', payload: KeyboardEvent): void
  /**
   * Triggers when cmd + esc are pressed
   * @param payload
   */
  (e: 'keydown-escape', payload: KeyboardEvent): void
  /**
   * Trigger Textarea update
   */
  (e: 'update:model-value', payload): void
}>()

const slots = useSlots()

const textarea = ref<VTextarea | null>(null)

const hasError = computed(() => props.state === InputState.Error)
const isDisabled = computed(() => props.state === InputState.Disabled)
const isReadonly = computed(() => props.state === InputState.Readonly)
const isWarn = computed(() => props.state === InputState.Warn)
const hasPrependInnerSlot = computed(() => {
  return !!slots['prepend-inner'] && slots['prepend-inner']().length > 0
})

const classes = computed(() => {
  const classes = [
    'k2-textarea',
    `k2-textarea--state-${props.state}`
  ]
  if (props.isFocusGrowable) {
    classes.push('k2-textarea--focus-growable')
  }

  return classes
})

const onKeydownPress = (e: KeyboardEvent) => {
  if (e.key === 'Enter') { emit('keydown-enter', e) }
  if (e.key === 'Escape') { emit('keydown-escape', e) }
}

const focus = () => {
  textarea.value?.focus()
}

defineExpose({
  focus
})
</script>

<style lang="scss" scoped>
@import '../scss/inputs';

$input-height: 30px;
$input-height-doubled: $input-height * 2;
$resize-to-closable: 12px;

.k2-textarea {
  @include k2-input-base;

  min-height: 32px;

  &:not(.k2-textarea--disabled) {
    :deep(textarea) {
      resize: vertical;
    }
  }

  .k2-textarea__v-textarea {
    &:hover {
      :deep(.v-input__control) {
        .v-field:not(.v-field--focused) {
          .v-field__outline__start, .v-field__outline__end {
            border-color: #000;
          }
        }
      }
    }

    &.warn {
      :deep(.v-input__control) {
        .v-field__outline__start, .v-field__outline__end {
          border-color: $k2-warning-base;
        }
      }
    }

    :deep(> .v-input__control > .v-field) {
      @include k2-background-surface-surface-bg;

      textarea {
        color: $k2-text-quaternary-text !important;
      }
    }

    :deep(.v-field) {
      min-height: 32px !important;

      textarea {
        @include k2-background-surface-surface-bg;

        &:focus {
          @include k2-background-surface-card-element-bg-hover;
        }
      }
    }

    :deep(.v-input__control) {
      .v-input__prepend-inner {
        align-self: flex-start;
        padding-top: 8px;

        i {
          &.primary--text {
            // Override Vuetify's important on focused state
            color: $k2-text-secondary-text !important;
          }

          @include k2-color-text-secondary-text;

          height: 20px;
          width: 20px;
        }
      }

      .v-field__field textarea {
        @include k2-font-text-text;
        @include k2-color-text-secondary-text;

        margin: 6px 6px 6px 0;
        padding-top: 2px;
        padding-bottom: 2px;
        min-height: 0px;
        mask-image: none;
        -webkit-mask-image: none;

        &:focus {
          @include k2-background-surface-card-element-bg-hover;

          min-height: max($input-height-doubled, var(--v-textarea-control-height));
          color: $k2-text-primary-text !important;
        }
      }

      .v-field__clearable {
        margin-left: 0px;
        padding-left: 2px;

        .v-input__icon--clear > button {
          @include k2-color-text-secondary-text;

          visibility: visible;
        }
      }

      .v-field--focused {
        background-color: $k2-surface-card-element-bg-hover !important;
      }

      .v-field__outline__start, .v-field__outline__end {
        transition-duration: 0.15s;
        transition-property: opacity, border-color;
      }
    }
  }

  &:not(.k2-textarea--focus-growable) {
    :deep(.v-input__control) {
      .v-text-field__slot textarea {
        &:focus {
          min-height: $input-height;
        }
      }
    }
  }

  .k2-textarea__prepend-inner-content {
    @include k2-font-text-text-bold;
    @include k2-color-text-secondary-text;
  }

  &--state-error {
    :deep(textarea) {
      @include k2-color-error-base;
    }

    .k2-textarea__prepend-inner-content {
      @include k2-color-error-base;
    }
  }
}
</style>
